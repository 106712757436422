import { formatPhoneNumber } from "../lib/formatters";

export default (opt = {}) => ({
	success: false,
	error: false,
	sending: false,
	status: -1,
	// the siteID can be found here https://www.klaviyo.com/settings/account/api-keys
	siteID: "WfPcRs",
	elementLabel: "Custom form",
	listID: "",
	scrollToOnSuccess: null,
	customerId: undefined,
	...opt,

	init() {
		const form = this.$root.querySelector("form") || this.$root;
		if (!form) {
			throw "Could not find <form>";
		}
		// put custom error messages in `data-error` attributes
		const inputList = [
			...form.querySelectorAll("input[data-error],textarea[data-error]"),
		];
		inputList.forEach((el) => {
			el.addEventListener("input", () => {
				el.setCustomValidity("");
				el.checkValidity();
			});
			el.addEventListener("invalid", () => {
				el.setCustomValidity(el.dataset.error);
			});
		});
	},

	isValid(form) {
		const email = form.querySelector(
			'input[name="email"],input[name="Email"],input[name="contact[email]"]',
		).value;
		if (!email) return false;
		return true;
	},

	submit(e) {
		const url =
			"//a.klaviyo.com/client/subscriptions/?company_id=" + this.siteID;

		e.preventDefault();
		e.stopPropagation();

		const form = this.$root.querySelector("form") || this.$root;
		if (!form) {
			throw "Could not find <form>";
		}

		if (!this.isValid(form)) return false;
		const entries = Object.fromEntries(new FormData(form).entries());
		// console.log('entries', entries);
		

		// The properties for the "subscription" JSON can be found
		// here https://developers.klaviyo.com/en/reference/create_client_subscription
		const data = {
			data: {
				type: "subscription",
				attributes: {
					profile: {
						data: {
							type: "profile",
							attributes: {
								// these are fixed fields
								email: entries["contact[email]"],
								phone_number: formatPhoneNumber(entries["contact[phone]"]),
								external_id: this.customerId,
								// anonymous_id: '01GDDKASAP8TKDDA2GRZDSVP4H',
								// _kx: 'J8fjcn003Wy6b-3ILNlOyZXabW6dcFwTyeuxrowMers%3D.McN66',
								first_name: entries["contact[first_name]"],
								last_name: entries["contact[last_name]"],
								organization: entries["contact[organization]"],
								// title: 'Engineer',
								// image: 'https://images.pexels.com/photos/3760854/pexels-photo-3760854.jpeg',
								// location: {
									// these are fixed fields
									// address1: entries.address,
									// address2: '1st floor',
									// city: entries.city,
									// country: 'United States',
									// region: 'NY',
									// zip: entries.zip,
									// timezone: 'America/New_York',
									// ip: '127.0.0.1'
								// },
								properties: {
									// you can put custom properties here
									website: entries["contact[website]"],
									years_in_business: entries["contact[years_in_business]"],
									distributors: entries["contact[distributors]"],
									other_products: entries["contact[other_products]"],
									message: entries["contact[message]"],
								},
							},
						},
					},
					custom_source: this.elementLabel,
				},
				relationships: {
					list: {
						data: {
							type: "list",
							id: this.listID,
						},
					},
				},
			},
		};

		// delete properties with empty objects on data
		const deleteEmpty = (obj) => {
			Object.keys(obj).forEach((key) => {
				if (obj[key] && typeof obj[key] === "object") {
					if (Object.keys(obj[key]).length === 0) {
						delete obj[key];
					} else {
						deleteEmpty(obj[key]);
					}
				}
			});
		};
		deleteEmpty(data.data);

		const json = JSON.stringify(data);

		// console.log('sending: ', data, json);

		try {
			this.sending = true;
			this.success = false;
			this.error = false;
			this.status = -1;
			return fetch(url, {
				method: "POST",
				headers: {
					revision: "2025-01-15", // required
					"content-type": "application/vnd.api+json",
				},
				body: json,
			})
				.then((res) => {
					// console.log('response', res);
					this.status = res.status;
					if (this.status === 202) {
						this.success = true;
						this.error = false;
					} else {
						return res.json();
					}
				})
				.then((json) => {
					// console.log('json', json);
					if (this.status === 202) {
						this.success = true;
						this.error = false;
					} else {
						this.success = false;
						this.error = json.errors || true;
					}
					return this.success;
				})
				.catch((err) => {
					this.success = false;
					this.error = true;
					console.error("error:" + err);
					return this.success;
				})
				.finally(() => {
					// console.log('finally');
					this.sending = false;
					if (Number.isInteger(this.scrollToOnSuccess))
						window.scrollTo(0, this.scrollToOnSuccess);
					else if (typeof this.scrollToOnSuccess == "string") {
						const el = document.querySelector(this.scrollToOnSuccess);
						if (el) {
							window.scrollTo({
								top: el.getBoundingClientRect().top + window.scrollY,
								behavior: "smooth",
							});
						}
					}
				});
		} catch (err) {
			console.log(err);
			this.error = true;
			this.sending = false;
			return Promise.reject(err);
		}
	},
});
