export function formatPhoneNumber(phone) {
    // Remove all non-numeric characters
    const cleaned = phone.replace(/\D/g, '');
    
    // Add + prefix if not present, handling both international and local formats
    const formatted = cleaned.length > 10 
      ? `+${cleaned}` 
      : `+1${cleaned}`; // Assumes US/Canada numbers for 10 digits or less
      
    return formatted;
  }

  