import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

declare global {
	interface Window {
		Alpine?: any;
		lenis?: any;
	}
}

interface ProductionOptions {
	qty?: number;
	product: any;
	selected_variant?: any;
	price?: number;
	compare_price?: number;
}

export default (opt: ProductionOptions) =>
({
	qty: 1,
	selected_variant: null,
	price: 0,
	compare_price: 0,

	...opt,

	showPopup: false,

	init()
	{
		if (!this.product)
			throw 'product error: must set "product"';

		// console.log('product', this.product);

		this.$watch('selected_variant', (newVal: any) => 
		{
			// console.log('selected_variant', newVal);
			this.updateCartPrice();
		})

		this.$watch('showPopup', (show: boolean) => {
			const el = this.$el.querySelector('.product-ingredients-popup');
			this.$nextTick(() => {
				if (show) {
					window.lenis?.stop();
					disableBodyScroll(el);

				}
				else {
					window.lenis?.start();
					enableBodyScroll(el);
				}
			})
		})

		if (this.product.variants.length == 1)
			this.selected_variant = this.product.variants[0];

		this.onOptionChange();
		this.setupPopup();
	},

	setupPopup() {
		const ingredientsBtn = this.$el.querySelector('a[href="#ingredients"]') as HTMLAnchorElement;
		if (!ingredientsBtn) return;
		ingredientsBtn.addEventListener('click', (e: MouseEvent) => {
			e.preventDefault();
			e.stopPropagation();
			this.showPopup = !this.showPopup;
		});
	},

	updateCartPrice() {
		this.price = this.selected_variant.price;
		this.compare_price = this.selected_variant.compare_price || 0;
	},

	onOptionChange() {
		const options = [...this.$root.querySelectorAll('input.option:checked')].map(x => x.value);
		// console.log('options', options);
		const optionsStr = JSON.stringify(options);
		this.product.variants.forEach(variant => {
			if (optionsStr == JSON.stringify(variant.options))
				this.selected_variant = variant;
		});
	},

	addToCart(e: Event, callback: (() => void) | null = null)
	{
		const objs: { id: string; quantity: number; selling_plan?: any }[] = [{
			id: this.selected_variant.id,
			quantity: this.qty,
		}];
		const plan = this.$root.querySelector('select[name="selling_plan"]');
		if (plan)
		{
			objs[0].selling_plan = plan.value;
		}
		// console.log(addToCart)
		this.$store.cart.add(objs)
			.then(() =>
			{
				this.$dispatch('open-mini-cart');
				if (callback)
					callback();
			});
	},


})