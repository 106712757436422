
/* NOTES:
	to get 'instafeed' app `hash` and `key` include App Block in the customiser,
	search the document <head></head> for a script with a url of this 
	form `https://storage.nfcube.com/instafeed-a0807f468b1ab0398736311aff088290.js?shop=57a846-d9.myshopify.com`,
	open it and near the bottom will be a line `var feed = new Instafeed({`
	after which will be the `hash` and `key` to use.
*/ 

interface Options {
	limit?: Number;
	domain?: String;
	hash?: String;
	key?: String;
	locale?: String;
}

export default (opt: Options = {}) => 
({
	domain: '',
	hash: '',
	key: '',
	locale: 'en',
	limit: 10,

	...opt,

	items: [],
	loading: true,

	init()
	{
		if (!this.hash)
			throw "Instafeed error: no 'hash' given";

		if (!this.domain)
			throw "Instafeed error: no 'domain' given";

		this.getFeed();
	},

	getFeed() 
	{
		const storage = localStorage.getItem("instafeed");

		if (storage) 
		{
			const data = JSON.parse(storage);
			// console.log('cached data', data);
			const date = new Date(data.timeStamp); // date last fetched
			const now = new Date(); // today

			date.setDate(date.getDate() + 1); // add 1 day onto last fetched date

			if (date < now || !data.items) 
			{
				this.loading = true;
				// if it was longer than a day ago, or is empty, refetch
				this.fetchFeed()
			} 
			else 
			{
				this.items = data.items;
				this.loading = false;
			}
		} 
		else 
		{
			this.fetchFeed()
		}
	},

	async fetchFeed()
	{
		if (!this.hash)
			throw 'Instafeed error: "hash" not defined';
		if (!this.domain)
			throw 'Instafeed error: "domain" not defined';

		try {
			const url = `https://instafeed.nfcube.com/feed/v6?fu=0&fid=0&hash=${this.hash}&locale=${this.locale}&limit=${this.limit}&account=${this.domain}`;

			fetch(url)
				.then(res => res.text())
				.then(response => {

					const data = JSON.parse(response);
					// console.log(data);

					if (data) {
						this.items = data.data;
						this.loading = false;
						this.cacheFeed();
					}
			});
		} 
		catch (err) 
		{
			console.log(err);
		}
	},

	cacheFeed() 
	{
		if (!this.items) {
			console.log('Instafeed: no items to cache');
			return;
		}
		localStorage.setItem(
			"instafeed",
			JSON.stringify(
			{
				timeStamp: new Date().getTime(),
				items: this.items,
			})
		);
	},

})