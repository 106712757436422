import { detectAppleDevice } from "../lib/detect-safari";

export default (opt = {}) => 
({
	delay: 1000,
	classToRemove: '',
	whenSafariIsLess: 0,
	whenIOSIsLess: 0,
	...opt,

	init() {
		const deviceInfo = detectAppleDevice();
		if (deviceInfo.isSafari) {
			// console.log('attempting marquee safari fix...')
			if (deviceInfo.safariVersion && !deviceInfo.isIOS) {
				const version = parseFloat(deviceInfo.safariVersion);
				this.$el.classList.remove(this.classToRemove);
				if (version >= this.whenSafariIsLess) {
					setTimeout(() => {
						this.$el.classList.add(this.classToRemove);
					}, this.delay)
				}
			}
			this.$el.style.backfaceVisibility = '';
			setTimeout(() => {
				this.$el.style.backfaceVisibility = 'hidden';
			}, this.delay)
		}
		if (deviceInfo.isIOS && deviceInfo.iOSVersion && this.whenIOSIsLess && this.classToRemove) {
			const version = parseFloat(`${deviceInfo.iOSVersion.major}.${deviceInfo.iOSVersion.minor}`)
			this.$el.classList.remove(this.classToRemove);
			if (version >= this.whenIOSIsLess) {
				setTimeout(() => {
					this.$el.classList.add(this.classToRemove);
				}, this.delay)
			}
		}
	}
})