
export default (opt: any = {}) => 
({
	collectionHandle: 'all',
	...opt,

	init() {
		if (window.innerWidth < 768)
			this.$el.querySelector('#filter-group').removeAttribute('open');
	},

	selectCollection(collectionHandle: string) {
		if (this.collectionHandle == collectionHandle)
			this.collectionHandle = 'all';
		else
			this.collectionHandle = collectionHandle;
		this.fetchData();
	},

	loadResults() {
		this.fetchData(this.$el.getAttribute('href'));
	},

	fetchData(href?: string) {
		let url = window.location.origin + 
					'/collections/' + this.collectionHandle +
					(window.location.search.length > 0 ? window.location.search + '&' : '?') + 
					'view=ajax';
		if (href)
			if (href.includes('?'))
				url = href + '&view=ajax';
			else
				url = href + '?view=ajax';

		const bbox = this.$root.querySelector('#groups-container').getBoundingClientRect();
		const scrollTop = window.scrollY + bbox.top - 135;

		return fetch(url)
			.then(res => res.text())
			.then(response => {
				const parser = new DOMParser();
				const doc = parser.parseFromString(response, "text/html");
				this.$root.innerHTML = doc.body.innerHTML;
				window.history.pushState(null, '', url.replaceAll('?view=ajax', '?').replaceAll('&view=ajax', ''));
				window.scrollTo({ left: 0, top: scrollTop, behavior: 'smooth' });
			});
	}
})