
interface ScrollOptions {
	selector?: string; 
	smooth?: boolean
}

export default (opt: ScrollOptions = {}) =>
({
	selector: null,
	...opt,


	init() {
		if (!this.selector)
			throw 'scrollTo error: no "selector" defined';

		this.$el.addEventListener('click', (e: MouseEvent) => this.scrollTo(e) );
	},

	scrollTo(evt) {

		const el = document.body.querySelector(this.selector as string);
		if (!el) {
			console.warn(`scrollTo error: "selector" [${this.selector}] not found in the document`);
			return;
		}
		evt.preventDefault();
		const headerBox = document.body.querySelector('header')?.getBoundingClientRect() || { height: 0 };
		const elBox = el.getBoundingClientRect();

		// console.log('scrollTo', this.$el, elBox, window.scrollY + elBox.top - headerBox.bottom);

		window.scrollTo({
			top: window.scrollY + elBox.top - headerBox.height,
			left: 0,
			behavior: opt.smooth ? 'smooth' : 'auto',
		})
	}
})