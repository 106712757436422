import { animateCallback } from '../lib/animate';
import { easeInOutLinear, easeInOutQuart, easeInQuart, easeOutQuart } from '../lib/easings'

interface Options {
	easing?: string,
	duration?: number,
	itemClass?: string,
}

const easingMap = {
	'linear': easeInOutLinear,
	'ease-in-out': easeInOutQuart,
	'ease-in': easeInQuart,
	'ease-out': easeOutQuart,
}

export default (opt: Options = {}) => 
({
	easing: 'linear',
	duration: 1000,
	itemClass: 'opacity-0',
	...opt,

	started: false,
	items: [],
	
	init() {
		const content = this.$root.innerHTML.trim();
		let items = content.split('<br>');
		items.forEach((line, i) => {
			items[i] = line.split('');
		});
		items = items.flat(Infinity);
		items.forEach((char, i) => {
			if (char == '<br>') return;
			items[i] = `<span class="${this.itemClass}">${char}</span>`;
		});
		this.$root.innerHTML = items.join('');
		this.items = [...this.$root.querySelectorAll('span')];
	},

	play(delay: number = 0) {
		if (this.started)	return;
		this.started = true;

		let prev_idx = 0;
		setTimeout(() => {
			animateCallback(
				(t) => {
					if (t < 0) return;
					const idx = Math.floor(t*this.items.length);
					for (let i = prev_idx; i < idx; i++) {
						this.items[i].classList.remove(this.itemClass);
					}
					prev_idx = idx;
				},
				this.duration,
				easingMap[this.easing]
			)
		}, delay);
	},
	
});