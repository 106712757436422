

export default (opt: any = {}) => 
({
	max: 6,
	showAll: false,
	...opt,

	count: 0,

	init() {
		this.max = window.innerWidth < 768 ? 4 : 6;
		window.addEventListener('resize', () => {
			this.max = window.innerWidth < 768 ? 4 : 6;
		})

		this.count = this.$el.querySelectorAll('.product-card').length;
	},
})