
export function easeInOutCss(x: number): number {
  return x * x * (3 - 2 * x);  // an approximation of CSS 'ease'
}

export function easeInOutCssInOut(x: number): number {
  return easeInOutQuad(x);  // an approximation of CSS 'ease-in-out'
}

export function easeInOutLinear(x: number): number {
	return x;
}

export function easeInOutSine(x: number): number {
	return -(Math.cos(Math.PI * x) - 1) / 2;
}

export function easeInOutQuad(x: number): number {
	return x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2;
}

export function easeInQuad(x: number): number {
	return x * x;
}

export function easeOutQuad(x: number): number {
	return 1 - (1 - x) * (1 - x);
}

export function easeInOutCubic(x: number): number {
	return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
}

export function easeInOutQuart(x: number): number {
	return x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2;
}

export function easeInQuart(x: number): number {
	return x * x * x * x;
}

export function easeOutQuart(x: number): number {
	return 1 - Math.pow(1 - x, 4);
}

export function easeInOutQuint(x: number): number {
	return x < 0.5 ? 16 * x * x * x * x * x : 1 - Math.pow(-2 * x + 2, 5) / 2;
}


export function easeInOutExpo(x: number): number {
	return x === 0
	? 0
	: x === 1
	? 1
	: x < 0.5 ? Math.pow(2, 20 * x - 10) / 2
	: (2 - Math.pow(2, -20 * x + 10)) / 2;
}

export function easeInExpo(x: number): number {
	return x === 0 ? 0 : Math.pow(2, 10 * x - 10);
}

export function easeOutExpo(x: number): number {
	return x === 1 ? 1 : 1 - Math.pow(2, -10 * x);
}