import 'vite/modulepreload-polyfill'

import Lenis from 'lenis'
import Alpine from "alpinejs";
import focus from "@alpinejs/focus";
import intersect from "@alpinejs/intersect";
import collapse from '@alpinejs/collapse'
import cart from "../lib/cart";
import { detectAppleDevice, detectAndroid } from "../lib/detect-safari";
import revealer from "../lib/revealer";

const deviceInfo = detectAppleDevice();
// console.log('deviceInfo', deviceInfo)
if (deviceInfo.appleDevice)
	document.documentElement.classList.add('apple-device')
if (deviceInfo.isIOS)
	document.documentElement.classList.add('ios')
if (deviceInfo.isIPad)
	document.documentElement.classList.add('ipad')
if (deviceInfo.iOSVersion) {
	document.documentElement.classList.add(`ios-${deviceInfo.iOSVersion.major}`, `ios-${deviceInfo.iOSVersion.major}.${deviceInfo.iOSVersion.minor}`)
	for (let i = 14; i <= 18; i++)
		if (deviceInfo.iOSVersion.major < i)
			document.documentElement.classList.add(`ios-pre-${i}`)
}
if (deviceInfo.isSafari)
	document.documentElement.classList.add('safari')
if (deviceInfo.safariVersion) {
	document.documentElement.classList.add(`safari-${deviceInfo.safariVersion.replaceAll('.', '-')}`)
	const version = parseInt(deviceInfo.safariVersion);
	document.documentElement.classList.add(`safari-${version}`)
	for (let i = 14; i <= 18; i++)
		if (version < i)
			document.documentElement.classList.add(`safari-pre-${i}`)
	if (parseFloat(deviceInfo.safariVersion) < 16.5)
		document.documentElement.classList.add(`safari-pre-16-5`)
}

if (!detectAndroid() && !deviceInfo.isIOS && window.innerWidth > 768) {
	if (deviceInfo.isSafari) {
		if (parseFloat(deviceInfo.safariVersion || "0") >= 16.5) {
			window['lenis'] = new Lenis({
				autoRaf: true,
			});
		}
	}
	else {
		window['lenis'] = new Lenis({
			autoRaf: true,
		});
	}
}

// set up Alpine
window['Alpine'] = Alpine;

Alpine.plugin(focus);
Alpine.plugin(intersect);
Alpine.plugin(collapse);

Alpine.store("cart", cart());

// Import all components from the components folder -- vite
const modules = import.meta.glob(['../components/*.ts', '../components/*.js'], {eager: true});

Object.entries(modules).forEach(([path, component]) => {
		const name = path.split('/').pop()?.split('.')[0]; // get the name from the file
		if (name) {
			Alpine.data(name, (component as any).default);
		}
});

document.addEventListener('DOMContentLoaded', () => {
	revealer();
	Alpine.start();
});
