interface PaginateOptions {
	currentPageIndex?: number; 
	isLoading?: boolean; 
	appendToContainer?: boolean; 
	noMoreContent?: boolean; 
	templateName?: string;
}

export default (options: PaginateOptions = {}) =>
({
	currentPageIndex: 1,
	isLoading: false,
	appendToContainer: true,
	noMoreContent: false,
	templateName: 'ajax',
	...options,

	init()
	{
		if (!this.$refs.container)
		{
			throw new Error('ajax-paginate error: no x-ref="container" found. aborting');
		}

		this.parser = new DOMParser();
		this.checkIfMore();
	},

	fetchData(pageIdx: number, replace: boolean, justCheck: boolean = false): Promise<boolean>
	{
		// NOTE: this relies on the existence of an alternate template of the current page for ex: blog.ajax.liquid

		justCheck = justCheck || false;

		const url = `${location.origin}${location.pathname}?view=${this.templateName}&page=${pageIdx}`;
		// console.log('fetching ', url)
		return fetch(url)
				.then(resp => 
				{
					// console.log('fecth response: ', resp)
					return resp.text();
				})
				.then(resp => 
				{
					const newHtml = (resp || '').trim();

					if (!newHtml)	return false;

					if (justCheck)	return true;

					if (replace)
					{
						this.$refs.container.innerHTML = newHtml;
					}
					else
					{
						const parser = new DOMParser();
						const doc = parser.parseFromString(newHtml, "text/html");

						// NOTE: avoid 'innerHTML += newHtml' as it causes flickering
						// this.$refs.container.innerHTML += newHtml;
						[...doc.body.children].forEach(child => 
							{
								// child.classList.add('inview__fade-in');
								// child.setAttribute('data-inview-once', '');

								this.$refs.container.append(child)
							});
					}

					// console.log('new content added successgully!')

					return true;
				})
	},

	checkIfMore()
	{
		this.isLoading = true;
		this.fetchData(this.currentPageIndex + 1, !this.appendToContainer, true)
			.catch( error =>
				{
					console.error('fetch error: ', error)
				})
			.then(contentAdded => 
				{
					if (!contentAdded)
					{
						this.noMoreContent = true;
						this.$dispatch(new CustomEvent('no-content', 
														{ 
															detail: { pageIndex: (this.currentPageIndex + 1) }
														}), 
													);
					}
				})
			.finally(() => this.isLoading = false)
	},

	loadNext()
	{
		this.isLoading = true;
		this.fetchData(this.currentPageIndex + 1, !this.appendToContainer)
			.catch( error =>
				{
					console.error('fetch error: ', error)
				})
			.then(contentAdded => 
				{
					if (contentAdded)
					{
						this.noMoreContent = false;
						this.currentPageIndex++;
						return this.fetchData(this.currentPageIndex + 2, !this.appendToContainer)
							.catch( error => {})
							.then(contentAdded => 
								{
									if (!contentAdded)
									{
										this.noMoreContent = true;
										this.$dispatch(new CustomEvent('no-content', 
																			{ 
																				detail: { pageIndex: (this.currentPageIndex + 2) }
																			}), 
																	);
									}
								})
					}
					else
					{
						this.noMoreContent = true;
						this.$dispatch(new CustomEvent('no-content', 
																		{ 
																			detail: { pageIndex: (this.currentPageIndex + 1) }
																		}), 
													);
					}
				})
			.finally(() => this.isLoading = false)
		
	},
})