import initCarousel from '../lib/init-carousel'

interface CarouselOptions {
	onSelect?: (index: number) => void;
	reInitEvent?: any;
	loop?: boolean;
}

export default (opt: CarouselOptions = {}) =>
({
	emblaApi: null,
	currentIndex: 0,
	count: 0,
	progress: 0,
	hasSettled: true,
	hasNext: true,
	hasPrev: false,
	canScrollNext: false,
	canScrollPrev: false,
	slidesInView: 0,
	slidesNotInView: 0,
	
	init() {
		const { onSelect, reInitEvent, ...options } = opt;
		this.emblaApi = initCarousel(this.$el, options, reInitEvent);
		const setActive = () => {
			const selectedIndex = this.emblaApi.selectedScrollSnap();
			this.currentIndex = selectedIndex;
			this.count = this.$el.querySelectorAll('.embla__slide').length;
			this.progress = Math.max(0, Math.min(1, this.emblaApi.scrollProgress()))*100;

			if (opt.loop) {
				this.hasNext = true;
				this.hasPrev = true;
			}
			else {
				this.hasNext = (this.currentIndex < this.count-1);
				this.hasPrev = (this.currentIndex > 0);
			}

			this.canScrollNext = this.emblaApi.canScrollNext();
			this.canScrollPrev = this.emblaApi.canScrollPrev();
			this.slidesInView = this.emblaApi.slidesInView();
			this.slidesNotInView = this.emblaApi.slidesNotInView();

			if (onSelect)
				onSelect.call(this.emblaApi, selectedIndex);
		}

		this.emblaApi
			.on('init', setActive)
			.on('reInit', setActive)
			.on('select', setActive)
			.on('scroll', () => { this.hasSettled = false; this.progress = Math.max(0, Math.min(1, this.emblaApi.scrollProgress()))*100; })
			.on('settle', () => { this.hasSettled = true;  })
	},

	goto(idx, instantly = false) {
		if (!this.emblaApi) return;
		if (this.emblaApi.selectedScrollSnap() != idx)
		{
			this.emblaApi.scrollTo(idx, instantly);
		}
	},

	gotoPrev(instantly = false) {
		if (!this.emblaApi) return;
		let idx = this.currentIndex - 1;
		if (opt.loop) 
			idx = idx % this.emblaApi.slideNodes().length;
		else
			idx = Math.max(idx, 0);
		if (this.emblaApi.selectedScrollSnap() != idx)
		{
			this.emblaApi.scrollTo(idx, instantly);
		}
	},

	gotoNext(instantly = false) {
		if (!this.emblaApi) return;
		let idx = this.currentIndex+1;
		if (opt.loop) 
			idx = idx % this.emblaApi.slideNodes().length;
		else
			idx = Math.min(idx, this.emblaApi.slideNodes().length - 1);
		if (this.emblaApi.selectedScrollSnap() != idx)
		{
			this.emblaApi.scrollTo(idx, instantly);
		}
	},
})

