
export default (opt: any = {}) => 
({
	msg: "Do you want to open Instagram in a new tab?",
	...opt,

	init() {
		if (window.innerWidth < 700) {
			this.$el.addEventListener('click', (e: MouseEvent) => { 
				e.preventDefault(); 
				if (window.confirm(this.msg)) {
					window.open(this.$el.href, '_blank');
				}
			});
		}
	}
})