import EmblaCarousel, { EmblaCarouselType, EmblaOptionsType, EmblaPluginType }  from 'embla-carousel'
import Autoplay from 'embla-carousel-autoplay'
import Fade from 'embla-carousel-fade'
import { addPrevNextBtnsClickHandlers } from './EmblaCarouselArrowButtons'
import { addDotBtnsAndClickHandlers } from './EmblaCarouselDotButton'

type AlignmentOptionType = 'start' | 'center' | 'end';

interface CarouselOptions {
	fade?: boolean;
	activeClass?: string;
	align?: AlignmentOptionType;
	loop?: boolean;
	slides?: string;
	autoplay?: number;
}

interface AttribTypes {
	align?: string;
	loop?: string;
	autoplay?: string;
}


export default function initCarousel(
	rootEl :HTMLElement, 
	opt: CarouselOptions = {}, 
	reInitEvent: string | null = null): EmblaCarouselType 
{

	if (!rootEl)
		throw "initCarousel: rootEl is undefined"

	const emblaNode: HTMLElement | null = rootEl.classList.contains('embla') ? rootEl : rootEl.querySelector('.embla');
	if (!emblaNode)
		throw 'initCarousel: no element with class "embla" found';
	const dotsNode = rootEl.querySelector('.embla__dots');
	const prevBtnNodes = [...rootEl.querySelectorAll('.embla__button--prev')] as HTMLElement[];
	const nextBtnNodes = [...rootEl.querySelectorAll('.embla__button--next')] as HTMLElement[];
	const dataAttributes = emblaNode.dataset as AttribTypes;
	const align = dataAttributes.align;
	const loop = dataAttributes.loop === 'true';
	
	const options: EmblaOptionsType = { 
		container: ".embla__container",
		align: (align || 'center') as AlignmentOptionType,
		loop,
		slides: '.embla__slide', // if not defined all direct children will be considere as slides

		...opt,
	 };
	const plugins: EmblaPluginType[] = [];

	// console.log('carousel options:', options);
	if (opt.autoplay) {
		plugins.push(Autoplay({ delay: opt.autoplay, stopOnMouseEnter: true }));
	}

	if (opt.fade) {
		// console.log('adding fade')
		plugins.push(Fade());
		// console.log('plugins', plugins)
	}
	
	const emblaApi = EmblaCarousel(emblaNode, options, plugins);

	if (reInitEvent) {
		window.addEventListener(reInitEvent, e => { 
			// const slideCount = rootEl.querySelectorAll('.embla__slide').length;
			emblaApi.reInit(options, plugins);
			// console.log('recieved reInitEvent...', slideCount, 'slides', emblaApi.slideNodes())
		})
	}

	const setActive = () => {
		const selected = emblaApi.selectedScrollSnap();
		const slides = [...rootEl.querySelectorAll('.embla__slide')];
		slides.forEach((slide, index) => {
			if (index === selected) {
				slide.classList.add('embla__slide--selected');
				if (opt.activeClass) slide.classList.add(opt.activeClass);
				slide.setAttribute('aria-selected', 'true');
			}
			else {
				slide.classList.remove('embla__slide--selected');
				if (opt.activeClass) slide.classList.remove(opt.activeClass);
				slide.setAttribute('aria-selected', 'false');
			}
		});
	}

	emblaApi
		.on('init', setActive)
		.on('reInit', setActive)
		.on('select', setActive)

	if (prevBtnNodes.length && nextBtnNodes.length) {
		const removePrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
			emblaApi,
			prevBtnNodes,
			nextBtnNodes
		);

		emblaApi.on('destroy', removePrevNextBtnsClickHandlers);
	}

	if (dotsNode) {
		const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
			emblaApi,
			dotsNode
		);

		emblaApi.on('destroy', removeDotBtnsAndClickHandlers)
	}

	// console.log('embla slides', emblaApi.slideNodes()); // Access API

	return emblaApi;
}