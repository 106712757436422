

interface FaqHeaderOptions {
	headerSelector?: string;
	anchorSelector?: string;
	activeClass?: string;
}

export default (opt: FaqHeaderOptions = {}) => 
({
	headerSelector: 'h2',
	anchorSelector: '.section-anchors',
	activeClass: 'active',
	...opt,

	init() {

		const headerElems = [...this.$el.querySelectorAll(this.headerSelector)];
		const anchorElems = [...document.querySelectorAll(this.anchorSelector)];

		const observer = new IntersectionObserver((entries) => {
			entries.forEach(entry => {
				const id = entry.target.getAttribute('id');
				const anchor = anchorElems.find(a => a.getAttribute('href') === `#${id}`);
				
				if (entry.isIntersecting) {
					anchorElems.forEach(a => a.classList.remove(this.activeClass));
					if (anchor) anchor.classList.add(this.activeClass);
				}
			});
		}, 
		{
			rootMargin: '-10% 0% -70% 0%'
		});

		headerElems.forEach(header => observer.observe(header));
	},
})