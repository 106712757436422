import debounce from 'debounce-fn';


export default (opt: { base64_text: string } = { base64_text: '' }) => 
({

	text: '',

	init() {
		this.text = atob(opt.base64_text).toUpperCase();
		window.addEventListener('resize', debounce(() => this.draw(), {wait: 100}));
		this.draw();
	},

	draw() {
		const firstChild = this.$el.firstElementChild;
		let canvas = this.$el.querySelector('canvas');
		if (!canvas) {
			canvas = document.createElement('canvas');
			canvas.class = 'absolute inset-0 w-full h-full';
			firstChild.appendChild(canvas);
		}
		const ctx = canvas.getContext('2d');
		const dpr = window.devicePixelRatio || 1;

		const width = firstChild.offsetWidth;
		const height = firstChild.offsetHeight;
		canvas.width = width * dpr;
		canvas.height = height * dpr;
		canvas.style.width = width+ 'px';
		canvas.style.height = height + 'px';
		ctx.clearRect(0, 0, canvas.width, canvas.height);

		ctx.scale(dpr, dpr);
		const style = window.getComputedStyle(firstChild);

		ctx.font = style.font;
		ctx.fontSize = style.fontSize;
		ctx.lineHeight = style.lineHeight;
		ctx.fillStyle = style.color;
		ctx.textAlign = "center";
		ctx.textBaseline = "middle";
		// NOTE: this doesn't wrap the text in multiple lines
		ctx.fillText(this.text, width / 2, height / 2 + height*0.1);
		ctx.scale(1/dpr, 1/dpr);
	}
})