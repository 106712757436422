import getVideoId from 'get-video-id';

interface VideoOptions {
	videoUrl?: string;
}

export default (opt: VideoOptions = {}) =>
	({
		service: null as string | null,
		videoId: null as string | null,
		embedUrl: null as string | null,
		isPlaying: false,
		abortCtrl: null as AbortController | null,

		init() {

			if (opt.videoUrl) {
				const videoObj = getVideoId(opt.videoUrl);
				this.service = videoObj.service;
				this.videoId = videoObj.id;

				if (this.service == 'youtube')
					this.embedUrl = `https://www.youtube.com/embed/${this.videoId}?modestbranding=1&showinfo=0&showsearch=0&loop=1&rel=0`;

				if (this.service == 'vimeo')
					this.embedUrl = `https://player.vimeo.com/video/${this.videoId}`;

			}

			const videoEl = this.$root.querySelector('video');

			if (!videoEl)	return;
			videoEl.addEventListener("pause", e => this.isPlaying = false);
			videoEl.addEventListener("waiting", e => this.isPlaying = false);
			videoEl.addEventListener("playing", e => this.isPlaying = true);
		},

		toggle() {
			if (this.isPlaying)
				this.pause();
			else
				this.play();
		},
		
		play() {
			const videoEl = this.$root.querySelector('video');

			if (!videoEl)	return;
			if (videoEl.paused) {
				if (videoEl.readyState >= 2) {
					videoEl.play().catch(error => {
						console.error('Error attempting to play video:', error);
					});
				}
				else {
					if (this.abortCtrl && !this.abortCtrl.aborted) 
						this.abortCtrl.abort();
					this.abortCtrl = new AbortController();
					const signal = this.abortCtrl.signal;

					videoEl.addEventListener("canplay", e => {
						videoEl.play().catch(error => {
							console.error('Error attempting to play video:', error);
						});
					}, { signal, once: true });
				}
			}
		},

		pause() {
			const videoEl = this.$root.querySelector('video');

			if (!videoEl)	return;
			if (this.abortCtrl && !this.abortCtrl.aborted) 
				this.abortCtrl.abort();
			this.abortCtrl = null;
			videoEl.pause()
		}
	})