// import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default (opt: any = {}) => 
({
	showMegaMenu: false, 
	showMobileMenu: false,

	showMiniCart: false,
	timeoutId: null, 

	...opt,

	init() {

		this.$watch('showMobileMenu', (show) => {
			const el = this.$el.querySelector('#mobile-menu-content-wrapper');
			this.$nextTick(() => {
				if (show) {
					window.lenis?.stop();
				}
				else {
					window.lenis?.start();
				}
			})
		})

		this.$watch('showMiniCart', (show) => {
			const el = document.body.querySelector('#minicart');
			console.log('showMiniCart', show, el);
			this.$nextTick(() => {
				if (show) {
					window.lenis?.stop();
				}
				else {
					window.lenis?.start();
				}
			})
		})

		document.addEventListener('open-mini-cart', (event: Event) => {
			// console.log('open-mini-cart');
			this.showMiniCart = true;
		});

	},
})