

interface AccordionOptions {
	groupName?: string; 
	open?: boolean;
}

interface AccordionEventDetail {
	groupName: string;
	parent: any;
}

export default (opt: AccordionOptions = {}) => 
({
	groupName: '',
	open: false,
	...opt,

	init() {
		if (this.groupName) {
			window.addEventListener('accordion-item-select', ((evt: CustomEvent<AccordionEventDetail>) => {
				if (evt.detail && 
					evt.detail.groupName == this.groupName && 
					evt.detail.parent != this
				)
					this.open = false;
			}) as EventListener);

			this.$watch('open', (newVal: boolean) => {
				if (newVal == true) {
					this.$dispatch('accordion-item-select', { groupName: this.groupName, parent: this });
				}
			})
		}
	}
})