
interface Options {
	dragSnapIndex?: number,
	snappingPointsSelector?: string,
	snappingPoints?: number[], // Snap points in percentage
}

export default (opt: Options = {}) => 
({
	snappingPointsSelector: '',
	snappingPoints: [], // Snap points in percentage
	dragSnapIndex: 0,
	...opt,
	
	knobXPercent: 0,
	
	isDragging: false,
	trackBBox: null,
	
	init() {
		this.snappingPoints = this.$el.querySelectorAll(this.snappingPointsSelector) as HTMLLabelElement[];
		const count = this.snappingPoints.length;
		this.snappingPoints = new Array(count).fill(0).map((_, i) => (i / (count - 1)) * 100);

		this.$watch('dragSnapIndex', (newVal: number) => {
			this.knobXPercent = this.snappingPoints[newVal];
		});

		this.dragSnapIndex = 0;
	},

	startDrag(event) {
		event.preventDefault();
		this.isDragging = true;
		
		const moveHandler = (e) => this.drag(e);
		const stopHandler = () => {
			this.stopDrag(moveHandler, stopHandler);
		};

		const track = this.$refs.track; 
		this.trackBBox = track.getBoundingClientRect();

		window.addEventListener('mousemove', moveHandler);
		window.addEventListener('touchmove', moveHandler);
		window.addEventListener('mouseup', stopHandler);
		window.addEventListener('touchend', stopHandler);
	},
	
	drag(event) {
		if (!this.isDragging) return;

		const clientX = event.touches ? event.touches[0].clientX : event.clientX;

		const newPosition = ((clientX - this.trackBBox.left) / this.trackBBox.width) * 100;
		this.knobXPercent = Math.min(Math.max(newPosition, 0), 100);
	},
	
	stopDrag(moveHandler, stopHandler) {
		this.isDragging = false;

		// Snap to nearest point
		const closestPoint = this.snappingPoints.reduce((prev, curr) =>
			Math.abs(curr - this.knobXPercent) < Math.abs(prev - this.knobXPercent) ? curr : prev
		);
		// 'knobXPercent' is set by the 'dragSnapIndex' watcher automatically
		this.dragSnapIndex = this.snappingPoints.indexOf(closestPoint);

		// Clean up event listeners
		window.removeEventListener('mousemove', moveHandler);
		window.removeEventListener('touchmove', moveHandler);
		window.removeEventListener('mouseup', stopHandler);
		window.removeEventListener('touchend', stopHandler);
	}
});