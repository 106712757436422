
interface TimelineOptions {
	slideCount?: number;
	scrollHandler?: (Event) => void;
}

export default (opt: TimelineOptions = {}) =>
({
	
	scrollHandler: null,
	slideCount: -1,
	...opt,

	activeIndex: 0,

	init() {

		if (window.innerWidth < 700) return;

		if (this.slideCount == -1)
			throw new Error("'slideCount' not set");
			
		if (this.slideCount == 0)	
			return;
		
		const observer = new IntersectionObserver(
			(entries, observer) =>
			{
				entries.forEach((entry) =>
				{
					if (entry.isIntersecting)
						this.start();
					else
						this.stop();
				});
			},
			{ }
		);

		observer.observe(this.$el);
	},

	start() {
		if (this.scrollHandler)	return;
		if (window.innerWidth < 700) return;
		
		const bbox = this.$el.getBoundingClientRect();
		const height = bbox.height;

		const top = bbox.top + window.scrollY;
		const bottom = bbox.bottom + window.scrollY;
		const extraHeight = bottom - (top + window.innerHeight);
		this.scrollHandler = (e) => {
			const currentScrollY = window.scrollY;
			if (currentScrollY <= top || currentScrollY > (bottom-window.innerHeight))	return;
			let t = (bottom - (currentScrollY + window.innerHeight)) / extraHeight;
			t = (1 - t);
			t *= this.slideCount;
			// console.log('t', t)
			const closestIndex = Math.floor(t);
			if (closestIndex != this.activeIndex)
			{
				this.activeIndex = closestIndex;
				// console.log('activeIndex', closestIndex);
			}
		};
		window.addEventListener('scroll', this.scrollHandler);
	},

	stop() {
		window.removeEventListener('scroll', this.scrollHandler);
		this.scrollHandler = null;
	}

})	