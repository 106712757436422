import { EmblaCarouselType }  from 'embla-carousel'

export const addDotBtnsAndClickHandlers = (emblaApi: EmblaCarouselType, dotsNode) => {
	let dotNodes: HTMLElement[] = []

	const addDotBtnsWithClickHandlers = () => {
		dotsNode.innerHTML = emblaApi
			.scrollSnapList()
			.map((_, index) => `<button class="embla__dot" type="button" aria-label="Go to slide ${index + 1}"></button>`)
			.join('')

		const scrollTo = (index) => {
			emblaApi.scrollTo(index)
		}

		dotNodes = Array.from(dotsNode.querySelectorAll('.embla__dot'))
		dotNodes.forEach((dotNode, index) => {
			dotNode.addEventListener('click', () => scrollTo(index), false)
		})
	}

	const toggleDotBtnsActive = () => {
		const previous = emblaApi.previousScrollSnap()
		const selected = emblaApi.selectedScrollSnap()
		if (dotNodes[previous]) dotNodes[previous].classList.remove('embla__dot--selected')
		if (dotNodes[selected]) dotNodes[selected].classList.add('embla__dot--selected')
	}

	emblaApi
		.on('init', addDotBtnsWithClickHandlers)
		.on('reInit', addDotBtnsWithClickHandlers)
		.on('init', toggleDotBtnsActive)
		.on('reInit', toggleDotBtnsActive)
		.on('select', toggleDotBtnsActive)

	return () => {
		dotsNode.innerHTML = ''
	}
}
