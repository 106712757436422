
export default (opt: any = {}) => 
({
	delay: 5*1000,
	...opt,
	showModal: false,
	openDrawer: false,
	
	init() {
		const storage = localStorage.getItem("newsletter-modal") || "false";
		if (storage) {
			const shown = JSON.parse(storage) || false;
			// console.log('newsletter-modal shown', shown);
			if (shown) return;
		}

		this.$watch('showModal', (newVal: boolean) => {
			setTimeout(() => {
				this.openDrawer = this.showModal;
			}, 300);
			if (!newVal) this.registerSuccess();
		})

		setTimeout(() => {
			this.showModal = true;
		}, this.delay);
	},

	registerSuccess() {
		localStorage.setItem("newsletter-modal", JSON.stringify(true));
	}
})