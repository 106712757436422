export default (opt = {}) => 
({
	duration: 1500,
	percent: 100,
	...opt,

	init() {

	},

	startCounter(delay = 0, duration = 1500) {
		
		const easeInQuad = (t) => {
			return t * t;
		};

		setTimeout(() => {
			const startTime = performance.now();
			const el = this.$refs.percent;
			const progressEl = this.$refs.progress;
			const animate = (timestamp) => {
				let progress = (timestamp - startTime) / duration;
				progress = Math.min(progress, 1.0);
				progress = easeInQuad(progress);
				const number = Math.ceil(progress * this.percent);
				el.innerText = Number(number).toLocaleString() + '%';
				progressEl.style.width = `${progress * this.percent}%`;

				if (progress < 1.0) requestAnimationFrame(animate);
			};
			requestAnimationFrame(animate);

		}, delay);
	}
})