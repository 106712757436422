import { EmblaCarouselType }  from 'embla-carousel'

const addTogglePrevNextBtnsActive = (emblaApi: EmblaCarouselType, prevBtn: HTMLElement[], nextBtn: HTMLElement[]) => {
	const togglePrevNextBtnsState = () => {
		if (emblaApi.canScrollPrev()) prevBtn.forEach(x => x.removeAttribute('disabled'))
		else prevBtn.forEach(x => x.setAttribute('disabled', 'disabled'))

		if (emblaApi.canScrollNext()) nextBtn.forEach(x => x.removeAttribute('disabled'))
		else nextBtn.forEach(x => x.setAttribute('disabled', 'disabled'))
	}

	emblaApi
		.on('select', togglePrevNextBtnsState)
		.on('init', togglePrevNextBtnsState)
		.on('reInit', togglePrevNextBtnsState)

	return () => {
		prevBtn.forEach(x => x.removeAttribute('disabled'))
		nextBtn.forEach(x => x.removeAttribute('disabled'))
	}
}

export const addPrevNextBtnsClickHandlers = (emblaApi: EmblaCarouselType, prevBtn: HTMLElement[], nextBtn: HTMLElement[]) => {
	const scrollPrev = () => {
		emblaApi.scrollPrev()
	}
	const scrollNext = () => {
		emblaApi.scrollNext()
	}
	prevBtn.forEach(x => x.addEventListener('click', scrollPrev, false))
	nextBtn.forEach(x => x.addEventListener('click', scrollNext, false))

	const removeTogglePrevNextBtnsActive = addTogglePrevNextBtnsActive(
		emblaApi,
		prevBtn,
		nextBtn
	)

	return () => {
		removeTogglePrevNextBtnsActive()
		prevBtn.forEach(x => x.removeEventListener('click', scrollPrev, false))
		nextBtn.forEach(x => x.removeEventListener('click', scrollNext, false))
	}
}
